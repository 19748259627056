import { rechnungenStore } from '@/state/rechnungenStore';
import { teamsStore } from '@/state/teamsStore';
import { Dictionary } from 'lodash';
import { RechnungSearchStatusFilter } from '../../../types';

export function setStoreFromParams(routeParams?: Dictionary<any>) {
  if (routeParams) {
    let newParams = { ...routeParams };
    if (routeParams?.leistungserbringer) {
      rechnungenStore.commit.setLeistungserbringerSelected(
        teamsStore.getters.teams.find(t => t.id === routeParams.leistungserbringer),
      );
    }
    if (routeParams?.team) {
      rechnungenStore.commit.setTeamSelected(teamsStore.getters.teams.find(t => t.id === routeParams.team));
    }
    if (routeParams?.status) {
      rechnungenStore.commit.setStatusFiter(routeParams.status);
    }
    if (routeParams?.bagatellschwelle) {
      rechnungenStore.commit.setBagatellschwelle(routeParams.bagatellschwelle);
    }
    if (routeParams?.erstelltFrom && routeParams?.erstelltTo) {
      rechnungenStore.commit.setDate({
        startDate: routeParams.erstelltFrom,
        endDate: routeParams.erstelltTo,
      });
    }
    if (routeParams?.faelligFrom && routeParams?.faelligTo) {
      newParams = setAlleStatus(newParams);
      rechnungenStore.commit.setDate({
        startDate: routeParams.faelligFrom,
        endDate: routeParams.faelligTo,
      });
    }

    return newParams;
  }
}

function setAlleStatus(newParams: Dictionary<any>) {
  rechnungenStore.commit.setStatusFiter(RechnungSearchStatusFilter.Alle);
  newParams.status = RechnungSearchStatusFilter.Alle;
  return newParams;
}
