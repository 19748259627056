import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import { defineComponent } from 'vue';
import { TeamTypes } from '../../../types';
import { teamIconClass } from '../../../common-ui';
import { chain, filter } from 'lodash';
import { behandlerService } from '@/services/behandlerService';
export default defineComponent({
  filters: {
    behandler: function behandler(value) {
      return behandlerService.transform(value);
    }
  },
  props: {
    teams: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    placeholder: {
      type: String,
      "default": ''
    },
    disabled: {
      type: Boolean,
      "default": false
    },
    teamSelected: {
      type: Object,
      "default": null
    },
    idSelected: {
      type: String,
      "default": null
    },
    onlySingles: Boolean
  },
  computed: {
    filteredTeams: function filteredTeams() {
      if (!this.teams) {
        return [];
      }

      if (this.onlySingles) {
        return filter(this.teams, function (team) {
          return team.typ === TeamTypes.SINGLE || team.typ === TeamTypes.BUNDLE;
        });
      }

      return this.teams;
    },
    selectedTeam: function selectedTeam() {
      var _this = this;

      var _a;

      return (_a = this.teamSelected) !== null && _a !== void 0 ? _a : this.teams.find(function (t) {
        return t.id === _this.idSelected;
      });
    }
  },
  methods: {
    onTeamSelected: function onTeamSelected(val) {
      this.$emit('team-selected', val);
    },
    teamIcon: function teamIcon(team) {
      return teamIconClass(team);
    },
    teamTitle: function teamTitle(team) {
      return chain(team.mitglieder).compact().map(function (m) {
        return behandlerService.getProperty(m);
      }).join(', ');
    },
    clear: function clear(val) {
      console.log('Clear item: ', val);
    }
  }
});