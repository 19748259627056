import "core-js/modules/es.number.constructor.js";
import { defineComponent } from 'vue';
import { numeralFilter } from '../../../common-ui/src/filters';
export default defineComponent({
  name: 'SpezialFilters',
  mixins: [numeralFilter],
  props: {
    filters: {
      type: Object,
      "default": function _default() {
        return {};
      }
    },
    displayedRowCount: {
      type: Number,
      "default": function _default() {
        return 0;
      }
    },
    displayedPatientCount: {
      type: Number,
      "default": function _default() {
        return 0;
      }
    },
    entryLabels: {
      type: Array,
      "default": function _default() {
        return ['Eintrag', 'Einträge'];
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    toggleFilterGroup: function toggleFilterGroup(filterKey) {
      this.$emit('toggleFilterGroup', filterKey);
    },
    filterGroupSelectedChanged: function filterGroupSelectedChanged(filterKey) {
      this.$emit('filterGroupSelectedChanged', filterKey);
    }
  }
});