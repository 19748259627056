import { BehandlerService } from '@/services/behandlerService';
import { rechnungenStore } from '@/state/rechnungenStore';
import { resolveUserName } from '@/state/usersStore';
import {
  behandlerArrayFormatter,
  behandlerFormatter,
  booleanJaNeinFormatter,
  classZeroValue,
  dateFormatter,
  dateLongYearFormatter,
  dateRawFormatter,
  euroFormatter,
  folgeTermineComparer,
  folgeTermineFormatter,
  folgeTermineRawDateFormatter,
  patientComparer,
  patientDisplayText,
  percent100Formatter,
  IRoseAGGridColumn,
} from '@rose/common-ui';
import { roseDayjs } from '../../../../base';
import { Units, IRechnungExtendedWithVeraltetLeistungenFiltered } from '@rose/types';
import { ValueFormatterParams } from 'ag-grid-community';
import { sumBy } from 'lodash';

const behandlerService = new BehandlerService();
export const rechnungenColumnDefs: IRoseAGGridColumn<IRechnungExtendedWithVeraltetLeistungenFiltered>[] = [
  {
    headerName: 'Patient',
    sortable: true,
    pinned: 'left',
    sort: 'asc',
    width: 300,
    comparator: patientComparer,
    valueFormatter: params => params && patientDisplayText(params.data), // used by export
    cellRendererSelector: (params: any) => {
      if (params.data.isSummary) {
        return { component: 'RechnungenSummary' };
      }
      return { component: 'PatientCell' };
    },
    applyFilterInSummaryRow: true,
    suppressMovable: true,
    noexport: true,
  },
  {
    headerName: 'Termin',
    field: 'folgetermine',
    cellClass: 'ag-cell-with-component',
    width: 30,
    sortable: true,
    resizable: true,
    comparator: folgeTermineComparer,
    valueFormatter: folgeTermineFormatter,
    cellRendererSelector: (params: any) => {
      if (params.data.isSummary) {
        return undefined;
      }
      return { component: 'TermineCell' };
    },
    exportWidth: 100,
    exportUnit: Units.DATUM,
    excelValueFormatter: folgeTermineRawDateFormatter,
    useFormatterForExport: true,
  },
  {
    headerName: 'Rechnungsnummer',
    field: 'rgnr',
    width: 110,
    resizable: true,
    sortable: true,
  },
  {
    headerName: 'Datum',
    field: 'tag',
    sortable: true,
    resizable: true,
    valueFormatter: dateLongYearFormatter,
    width: 90,
    suppressMovable: true,
    exportUnit: Units.DATUM,
    excelValueFormatter: dateRawFormatter,
    useFormatterForExport: true,
  },
  {
    headerName: 'Honorar',
    field: 'honorar',
    width: 85,
    valueFormatter: euroFormatter,
    cellClass: classZeroValue,
    cellStyle: { 'text-align': 'right' },
    sortable: true,
    resizable: true,
    suppressMovable: true,
    exportUnit: Units.EURO,
  },
  {
    headerName: 'Labor',
    field: 'labor',
    width: 85,
    valueFormatter: euroFormatter,
    cellClass: classZeroValue,
    cellStyle: { 'text-align': 'right' },
    sortable: true,
    resizable: true,
    suppressMovable: true,
    exportUnit: Units.EURO,
  },
  {
    headerName: 'Material',
    cellClass: classZeroValue,
    field: 'material',
    width: 85,
    valueFormatter: euroFormatter,
    cellStyle: { 'text-align': 'right' },
    sortable: true,
    resizable: true,
    suppressMovable: true,
    exportUnit: Units.EURO,
  },
  {
    headerName: 'Gesamt',
    field: 'gesamt',
    width: 85,
    valueFormatter: euroFormatter,
    cellStyle: { 'text-align': 'right' },
    sortable: true,
    resizable: true,
    suppressMovable: true,
    cellClass: (params: any) => {
      let hkpGesamt = params.data.hkpGesamt;
      if (hkpGesamt < params.data.gesamt) {
        return 'cell-warning';
      }
    },
    tooltipValueGetter: (params: any) => {
      let hkpGesamt = params.data.hkpGesamt;
      if (hkpGesamt < params.data.gesamt) {
        return 'Der Rechnungsbetrag ist höher als der HKP-Gesamtbetrag';
      }
    },
    exportUnit: Units.EURO,
  },
  {
    // rot wenn > als gesamt
    headerName: 'HKP',
    field: 'hkpGesamt',
    width: 85,
    valueFormatter: euroFormatter,
    cellStyle: { 'text-align': 'right' },
    cellClass: classZeroValue,
    sortable: true,
    resizable: true,
    suppressMovable: true,
    exportUnit: Units.EURO,
  },
  {
    headerName: 'Erstattung',
    field: 'erstattung',
    width: 85,
    cellClass: classZeroValue,
    valueFormatter: params => euroFormatter({ ...params, value: -params.value }),
    cellStyle: { 'text-align': 'right' },
    sortable: true,
    resizable: true,
    suppressMovable: true,
    exportUnit: Units.EURO,
  },
  {
    headerName: 'Rest',
    field: 'rest',
    cellClass: classZeroValue,
    width: 85,
    valueFormatter: euroFormatter,
    cellStyle: { 'text-align': 'right' },
    sortable: true,
    resizable: true,
    suppressMovable: true,
    exportUnit: Units.EURO,
  },
  {
    headerName: 'Erlassen',
    field: 'erlassen',
    cellClass: classZeroValue,
    width: 85,
    valueFormatter: euroFormatter,
    cellStyle: { 'text-align': 'right' },
    sortable: true,
    resizable: true,
    suppressMovable: true,
    exportUnit: Units.EURO,
  },
  {
    headerName: 'RZ',
    field: 'rechenzentrum',
    width: 60,
    valueFormatter: booleanJaNeinFormatter,
    resizable: true,
    sortable: true,
    suppressMovable: true,
  },
  {
    headerName: 'Fällig',
    field: 'faellig',
    sortable: true,
    resizable: true,
    valueFormatter: dateLongYearFormatter,
    width: 90,
    suppressMovable: true,
    cellClass: params => {
      if (params.data?.bezahlt || !params.data?.faellig) {
        return;
      }
      let ueberFaellig = roseDayjs(params.data.faellig).isBefore(roseDayjs());
      if (ueberFaellig) {
        return 'cell-warning';
      }
    },
    exportUnit: Units.DATUM,
    excelValueFormatter: dateRawFormatter,
    useFormatterForExport: true,
  },
  {
    headerName: 'Bezahltdatum',
    field: 'bezahltdatum',
    sortable: true,
    resizable: true,
    valueFormatter: dateLongYearFormatter,
    width: 80,
    suppressMovable: true,
    cellClass: params => {
      if (!params.data?.faellig) {
        return;
      }
      let ueberFaellig =
        params.data.bezahltdatum &&
        params.data.faellig &&
        roseDayjs(params.data.bezahltdatum).isAfter(params.data.faellig);
      if (ueberFaellig) {
        return 'cell-warning';
      }
    },
    exportUnit: Units.DATUM,
    excelValueFormatter: dateRawFormatter,
    useFormatterForExport: true,
  },
  {
    headerName: 'Stammbehandler',
    field: 'behandler',
    valueFormatter: behandlerFormatter(behandlerService),
    width: 130,
    sortable: true,
    resizable: true,
    suppressMovable: true,
  },
  {
    headerName: 'Leistungserbringer',
    field: 'honorardetails.behandlerverteilung',
    cellRenderer: 'LeistungserbringerCell',
    cellRendererParams: { typ: 'rechnungen' },
    valueFormatter: behandlerArrayFormatter(behandlerService, v => v.behandler),
    width: 150,
    resizable: true,
    suppressMovable: true,
    exportWidth: 250,
  },
  {
    headerName: 'Mahnstufe',
    field: 'mahnstufe',
    width: 90,
    resizable: true,
    sortable: true,
    suppressMovable: true,
    cellRenderer: 'MahnstufeInfoCell',
  },
  {
    headerName: 'Mahnstopp',
    field: 'mahnstoppDatum',
    editable: true,
    cellEditor: 'DateRangeCell',
    valueFormatter: dateFormatter,
    onCellValueChanged: v => rechnungenStore.dispatch.saveRechnungMahninfo(v),
    width: 125,
    resizable: true,
    sortable: true,
    suppressMovable: true,
    cellClass: params => {
      let mahnstoppDatum = params.data?.mahnstoppDatum;
      if (mahnstoppDatum && roseDayjs(mahnstoppDatum).isBefore(roseDayjs())) {
        return 'cell-warning';
      }
    },
  },
  {
    headerName: 'Mahnstopp Kommentar',
    field: 'mahnstoppKommentar',
    editable: true,
    onCellValueChanged: (v: any) => rechnungenStore.dispatch.saveRechnungMahninfo(v),
    resizable: true,
    sortable: true,
    suppressMovable: true,
    width: 200,
  },
  {
    headerName: 'Letzte Bearbeitung',
    field: 'mahnstoppErsteller',
    // @ts-ignore
    valueFormatter: (params: ValueFormatterParams<IRechnungExtendedWithVeraltetLeistungenFiltered, string>) =>
      resolveUserName(params.value),
    resizable: true,
    sortable: true,
    suppressMovable: true,
    width: 200,
  },
  {
    headerName: 'Summe gefilterter Leistungen',
    field: 'filteredLeistungen',
    // @ts-ignore
    valueFormatter: (params: ValueFormatterParams<IRechnungExtendedWithVeraltetLeistungenFiltered, string>) =>
      euroFormatter({ value: sumBy(params.data?.filteredLeistungen, l => l.betrag) } as any),
    resizable: true,
    sortable: true,
    suppressMovable: true,
    width: 200,
  },
];

export const rechnungenColumnDefsExtended: IRoseAGGridColumn<IRechnungExtendedWithVeraltetLeistungenFiltered>[] = [
  {
    headerName: 'Von (Leistungszeitraum)',
    field: 'von',
    width: 100,
    valueFormatter: dateLongYearFormatter,
    sortable: true,
    resizable: true,
    suppressMovable: true,
    exportUnit: Units.DATUM,
    excelValueFormatter: dateRawFormatter,
    useFormatterForExport: true,
  },
  {
    headerName: 'Bis (Leistungszeitraum)',
    field: 'bis',
    width: 100,
    valueFormatter: dateLongYearFormatter,
    sortable: true,
    resizable: true,
    suppressMovable: true,
    exportUnit: Units.DATUM,
    excelValueFormatter: dateRawFormatter,
    useFormatterForExport: true,
  },
  {
    headerName: 'Ertrag',
    field: 'ertrag',
    width: 85,
    valueFormatter: euroFormatter,
    cellClass: classZeroValue,
    cellStyle: { 'text-align': 'right' },
    sortable: true,
    resizable: true,
    suppressMovable: true,
    exportUnit: Units.EURO,
  },
  {
    headerName: 'Ertrag %',
    field: 'ertragprozent',
    width: 85,
    valueFormatter: percent100Formatter,
    cellClass: classZeroValue,
    cellStyle: { 'text-align': 'right' },
    sortable: true,
    resizable: true,
    suppressMovable: true,
    exportUnit: Units.PROZENTEROUNDED,
  },
];
